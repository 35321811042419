import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Loggut() {
    const navigate = useNavigate();
    useEffect(() => {
        document.title = 'Din side - Justervesenet';
        sessionStorage.clear();
        navigate('/', { replace: true });
    });
    return (
        <div></div>
    )
}

export default Loggut;