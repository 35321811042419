import { Alert, Backdrop, Box, Button, Card, CardContent, CircularProgress, Grid, Link, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import AccountCircle from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Tjenesteknapper from '../komponenter/Tjenesteknapper';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function Forsiden() {
    const [isLoading, setisLoading] = useState(true);
    const jvbruker = sessionStorage.getItem('jvbruker');
    const jvnavn = sessionStorage.getItem('jvnavn');
    const jvmobilnummer = sessionStorage.getItem('jvmobilnummer');
    const jvaktornavn = sessionStorage.getItem('jvaktornavn');
    const jvaktororgnr = sessionStorage.getItem('jvaktororgnummer');
    const token = sessionStorage.getItem("jvtoken") || "";

    const [jvbrukerverifisert, setjvbrukerverifisert] = useState(false);
    const [jvmobilnummerverifisert, setjvmobilnummerverifisert] = useState(false);
    const [jvaktorverifisert, setjvaktorverifisert] = useState(false);

    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
        if (token !== "") {
            setisLoading(false);
        }
        else {
            window.location.assign("/logginn");
        }
        const jvbrukerverifisert = sessionStorage.getItem('jvbrukerverifisert');
        const isVerified = jvbrukerverifisert === 'true';
        setjvbrukerverifisert(isVerified);
        const jvmobilnummerverifisert = sessionStorage.getItem('jvmobilnummerverifisert');
        const isVerifiedMobil = jvmobilnummerverifisert === 'true';
        setjvmobilnummerverifisert(isVerifiedMobil);
        const jvaktorverifisert = sessionStorage.getItem('jvaktorverifisert');
        const isVerifiedAktor = jvaktorverifisert === 'true';
        setjvaktorverifisert(isVerifiedAktor);
        setisLoading(false);
    }, [token]);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />

                        <Grid container spacing={2}>
                            <Grid item xs={12} mt={2} p={2} ml={2}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid container sx={{ alignItems: 'flex-start' }}>
                                            {/* Første kolonne: jvnavn, jvbruker, jvmobilnummer */}
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <AccountCircle className='jvtexticon' /> {jvnavn}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <EmailIcon className='jvtexticonnc' /> {jvbruker} {(!jvbrukerverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3, mb: 3 }}>
                                                    <PhoneIcon className='jvtexticonnc' /> {jvmobilnummer} {(!jvmobilnummerverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Button
                                                    size="small"
                                                    href="/profil"
                                                    variant="outlined"
                                                    startIcon={<SettingsIcon />}
                                                    sx={{ mr: 1 }}
                                                >
                                                    <Text tid="minprofil" />
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
                                                {/* Andre kolonne: jvaktornavn, jvaktororgnr */}
                                                <Typography variant="h6" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <BusinessIcon className='jvtexticon' /> {jvaktornavn} {(!jvaktorverifisert) ? <WarningAmberIcon className='color-yellow' sx={{ ml: 1 }} /> : <VerifiedIcon className='jviconverified' sx={{ ml: 1 }} />}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' sx={{ ml: 3 }}>
                                                    <ListAltIcon className='jvtexticonnc' /> {jvaktororgnr}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                                {(!jvbrukerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="mobilikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                {(!jvmobilnummerverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="epostikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="gjennomforeverifisering" /></Alert>
                                }
                                {(!jvaktorverifisert) &&
                                    <Alert sx={{ mt: 2 }} severity="error"><Text tid="dintilknytningaktor" /> <b>{jvaktornavn}</b> <Text tid="erikkeverifisert" /> <Text tid="gaatil" /> <Link href='/profil'><Text tid="minprofil" /></Link> <Text tid="formerinformasjon" /></Alert>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" m={2}>
                                    <Grid container spacing={2} mt={2}>
                                        <Grid item xs={12}>
                                            <Alert sx={{ mb: 2 }} severity="warning">
                                                <b>Viktig informasjon</b>
                                                <p>
                                                    Vi øker sikkerheten for å kunne innføre nye tjenester og muligheter. Fra <b>21.10.2024</b> må din konto være verifisert tilknyttet en virksomhet for å kunne bruke tjenestene på denne siden.<br />
                                                    For å bli verifisert til en virksomhet, må du enten ha tilgang til Altinn for virksomheten, eller bli verifisert av en administrator for virksomheten. Gå til <a href='/profil'>Min profil</a> og trykk "Bli administrator" dersom du har tilgang til Altinn.
                                                </p>
                                                <p>
                                                    Alle brukerkontoer på denne siden skal også være personlige slik at vi vet hvem som sender inn meldinger på vegne av en aktør. Det innføres også derfor verifisering av e-post og mobilnummer. Hvis navnet på din brukerkonto som per nå er <b>{jvnavn}</b> ikke er ditt personlige navn så må dette endres.
                                                </p>
                                            </Alert>
                                            <Typography variant="h5" color="text.secondary" component="h2" sx={{ mb: 3 }}>
                                                <Text tid="tjenester" />
                                            </Typography>
                                            <Box display="flex" width="100%" maxWidth="900px">
                                                <Box m={2}>
                                                    <Tjenesteknapper link="/taksameter" Icon="LocalTaxiIcon" Tittel="taksameter" Info="servicetaksameterinfo" />
                                                </Box>
                                                <Box m={2}>
                                                    <Tjenesteknapper link="/innmeldinger" Icon="AssignmentIcon" Tittel="innmeldinger" Info="innmeldingerinfo" />
                                                </Box>
                                                <Box m={2}>
                                                        <Tjenesteknapper link="/aarsavgift" Icon="PointOfSaleIcon" Tittel="aarsavgift" Info="aarsavgiftinfo" />
                                                </Box>
                                                <Box m={2}>
                                                    <Tjenesteknapper link="/arkiv" Icon="FolderIcon" Tittel="arkiv" Info="arkivinfo" />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main>
    );
}

export default Forsiden;