import { Alert, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Input, InputLabel, Link, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Grid from '@mui/material/Grid2';
import { useParams } from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';

const APIURL = process.env.REACT_APP_APIURL;


function InnmeldingerVirksomheter() {
    const { orgnr } = useParams();
    const token = sessionStorage.getItem("jvtoken") || "";
    const minvirksomhetnavn = sessionStorage.getItem("jvaktornavn") || "";
    const [isLoading, setisLoading] = useState(true);
    const [aktorer, setAktorer] = useState<any[]>([]);
    const [virksomheter, setVirksomheter] = useState<any[]>([]);
    const [undereenheter, setUnderenheter] = useState<any[]>([]);
    const [ModalNyAktor, setModalNyAktor] = useState(false);
    const [ModalBeOmTilgang, setModalBeOmTilgang] = useState(false);
    const [ModalOSS, setModalOSS] = useState(false);
    const [TilgangOrgNr, setTilgangOrgNr] = useState(0);
    const [TilgangTilAktorId, setTilgangTilAktorId] = useState(0);
    const [TilgangServicekode, setTilgangServicekode] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const ModalNyAktorLukk = () => {
        setModalNyAktor(false);
    };

    const modalBeOmTilgangAapne = (orgnr: number, aktorid: number) => {
        setModalBeOmTilgang(true);
        setTilgangOrgNr(orgnr);
        setTilgangTilAktorId(aktorid);
    }
    const modalBeOmTilgangLukk = () => {
        setModalBeOmTilgang(false);
    }

    const handleTilgangKodeChange = (value: string) => {
        setTilgangServicekode(value);
    }


    const ModalNyAktorAapne = async (orgnr: number) => {
        console.log(orgnr);
        setModalNyAktor(true);
    };

    const hentVirksomheter = () => {
        setisLoading(true);
        axios.get(APIURL + "/innmelding/virksomhetmedunderenheter/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data[0].underenheter);
                    setVirksomheter(response.data);
                    setUnderenheter(response.data[0].underenheter);
                }   
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setisLoading(false);
            });
    };

    useEffect(() => {
        document.title = 'DinSide - Justervesenet';
        hentVirksomheter();
        setisLoading(false);
    }, []);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                Innmeldinger
                            </Typography>
                        </Breadcrumbs>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Grid container spacing={2} p={2}>
                            <Grid size={12}>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    <BusinessIcon className='jvcardtitleicon' /> {virksomheter[0]?.Navn}
                                </Typography>

                            </Grid>
                            <Grid size={12}>
                                <Typography variant="h6" component="h2" className='jvcardtitle'>
                                    Underenheter
                                </Typography>
                                {((aktorer[0]?.resultater) && (aktorer[0]?.resultater > 10)) && <Typography variant="body2"><i><Text tid="flereenntifunn" /></i></Typography>}
                                <p></p>
                                {undereenheter && undereenheter.map(({ orgnr, Navn, forretningsadr, forradrpostnr, forradrpoststed, forradrkommnavn, MetricAktorId, MetricAktiv, MetricAntallInstrumenter, MetricAntallUnderenheter, MetricAktorAktorTilgang }: any, index: number) => {
                                    return <span key={index}>
                                        {(MetricAktorId) ?
                                            <Link
                                                href={
                                                    (MetricAktiv && MetricAktorAktorTilgang) ?
                                                        "/virksomhet/" + orgnr : "#"
                                                }
                                                underline="none"
                                                onClick={(MetricAktiv && !MetricAktorAktorTilgang) ? () => { modalBeOmTilgangAapne(orgnr, MetricAktorId) } : undefined}
                                            >
                                                <Card variant="outlined" className={(MetricAktiv) ? "CardLinkA" : "CardLinkNA"} sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {Navn} &nbsp; [{orgnr}]
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                            <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                        </Typography>
                                                        <p></p>
                                                        {(MetricAktorId) ?
                                                            (MetricAktiv) ?
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="success" /> Registrert hos Justervesenet
                                                                </Typography>
                                                                :
                                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                    <CheckBoxIcon className='jvtexticon' color="error" /> Registrert hos Justervesenet, men ikke aktiv
                                                                </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> Ikke registrert hos Justervesenet
                                                            </Typography>
                                                        }
                                                        {(MetricAntallInstrumenter !== 0) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> {MetricAntallInstrumenter} aktive instrumenter
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> ingen aktive instrumenter
                                                            </Typography>
                                                        }
                                                        {(MetricAntallUnderenheter !== 0) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> {MetricAntallUnderenheter} aktive underenheter
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> ingen aktive underenheter
                                                            </Typography>
                                                        }
                                                        {(MetricAktorAktorTilgang) ?
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <CheckBoxIcon className='jvtexticon' color="success" /> {minvirksomhetnavn} har tilgang til denne virksomheten
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                                <DisabledByDefaultIcon className='jvtexticon' color="error" /> {minvirksomhetnavn} har ingen tilgang til denne virksomheten
                                                            </Typography>
                                                        }
                                                        <p></p>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                            :
                                            <Link
                                                href="#"
                                                underline="none"
                                                onClick={() => { ModalNyAktorAapne(orgnr) }}
                                            >
                                                <Card variant="outlined" className="CardLinkNA" sx={{ mb: 2 }}>
                                                    <CardContent>
                                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                                            {Navn} &nbsp; [{orgnr}]
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                            <HomeIcon className='jvtexticon' /> {forretningsadr}, {forradrpostnr} ({forradrpoststed}) [{forradrkommnavn}]
                                                        </Typography>
                                                        <p></p>
                                                        <Typography variant="body2" color="text.secondary" className='jvtypeicon'>
                                                            <DisabledByDefaultIcon className='jvtexticon' color="error" /> Ikke registrert hos Justervesenet
                                                        </Typography>
                                                        <p></p>
                                                    </CardContent>
                                                </Card>
                                            </Link>
                                        }
                                    </span>
                                })
                                }
                            </Grid>
                        </Grid>




                    </div>
            }
        </main >
    )
}

export default InnmeldingerVirksomheter;