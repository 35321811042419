import { Alert, Backdrop, Box, Button, CircularProgress, FormControl, Grid, Input, InputLabel, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import LoginIcon from '@mui/icons-material/Login';
import Footer from '../komponenter/Bunn';
import axios from 'axios';

const APIURL = process.env.REACT_APP_APIURL;

function GlemtPassord() {
    useEffect(() => {
        document.title = 'Din Side - Justervesenet';
    }, []);
    const [isWaiting, setisWaiting] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        setisWaiting(true);
        event.preventDefault();
        var formdata = new FormData(event.currentTarget);

        axios.post(APIURL + "/profil/glemtpassord", formdata, { withCredentials: true, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' } })
            .then((response: any) => {
                setisWaiting(false);
                if (response.status === 200) {
                    window.location.assign("/");
                }
                else {
                }
            })
            .catch((e: Error) => {
                let snackmsg = Text({ tid: "apifeilglemtpassord" });
                setSnackMelding(snackmsg);
                setOpenSnackError(true);
                setisWaiting(false);
            });
    }

    return (
        <main>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isWaiting}
            >
                <CircularProgress color="inherit" /> &nbsp; <Text tid="oppdaterermelding" />
            </Backdrop>
            <ToppMeny />
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                    {snackMelding}
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackError} autoHideDuration={6000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                    {snackMelding}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box m={2}>
                        <Typography variant="h5" color="text.primary" component="h2" gutterBottom>
                            <Text tid="glemtpassordoverskrift" /><p></p>
                        </Typography>
                        <Alert severity="info" sx={{ mb: 3 }}>
                            <Text tid="glemtpassordinfo" />
                        </Alert>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="epost"><Text tid="epost" /></InputLabel>
                                        <Input
                                            autoFocus
                                            id="epost"
                                            name="epost"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} sx={{ mb: 3 }}>
                                    <FormControl fullWidth sx={{ mb: 2 }} variant="standard">
                                        <InputLabel htmlFor="mobilnummer"><Text tid="mobilnummer" /></InputLabel>
                                        <Input
                                            id="mobilnummer"
                                            name="mobilnummer"
                                            required
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                variant="contained"
                                startIcon={<LoginIcon />}
                                sx={{ mr: 1 }}
                            >
                                <Text tid="glemtpassord" />
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
        </main>
    )
}
export default GlemtPassord;