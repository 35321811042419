import React, { useState, createContext, useContext } from 'react';

import { languageOptions, dictionaryList } from '../spraak';

export const LanguageContext = createContext({
  userLanguage: 'no',
  dictionary: dictionaryList.no
});

export function LanguageProvider({ children }: { children: any }) {
  const defaultLanguage = window.localStorage.getItem('jvlang');
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'no');
  const provider = {
    userLanguage,
    dictionary: (dictionaryList as any)[userLanguage],
    userLanguageChange: (selected: any) => {
      const newLanguage = (languageOptions as any)[selected] ? selected : 'no'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('jvlang', newLanguage);
    }
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export function Text({ tid }: { tid: any }) {
  const languageContext = useContext(LanguageContext);
  return (languageContext.dictionary as any)[tid] || tid + " (mangler i språkfil)";
};