import { AppBar, Avatar, Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, Link, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Slide, Snackbar, Tab, Tabs, TextField, TextFieldProps, Toolbar, Typography } from '@mui/material'
import React, { useEffect, useState, forwardRef } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import HomeIcon from '@mui/icons-material/Home';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import PlaceIcon from '@mui/icons-material/Place';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionProps } from '@mui/material/transitions';
import Grid from '@mui/material/Grid2';
const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function AarsavgiftBensin() {
    const { orgnr } = useParams();
    const token = sessionStorage.getItem("jvtoken") || "";
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [bensinAarsak, setBensinAarsak] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [AktivUtsalgsstedId, setAktivUtsalgsstedId] = useState(0);
    const [aktor, setAktor] = useState<any[]>([]);
    const [underenheter, setUnderenheter] = useState<any[]>([]);
    const [nyUnderenhetId, setnyUnderenhetId] = useState(0);
    const [ModalEndreAktor, setModalEndreAktor] = useState(false);
    const [ModalOpprettUnderenhet, setModalOpprettUnderenhet] = useState(false);
    const [ModalRapporterBensin, setModalRapporterBensin] = useState(false);
    const [ModalRapporterIngenBensin, setModalRapporterIngenBensin] = useState(false);


    const handleSelectChangeAarsak: TextFieldProps['onChange'] = (event) => {
        setBensinAarsak(event.target.value as string);
    };

    const ModalRapporterBensinAapne = (aktorid: number) => {
        setAktivUtsalgsstedId(aktorid);
        setModalRapporterBensin(true);
    }



    const ModalRapporterBensinLukk = () => {
        setAktivUtsalgsstedId(0);
        setModalRapporterBensin(false);
    }

    const ModalRapporterIngenBensinAapne = (aktorid: number) => {
        setAktivUtsalgsstedId(aktorid);
        setModalRapporterIngenBensin(true);
    }

    const ModalRapporterIngenBensinLukk = () => {
        setAktivUtsalgsstedId(0);
        setModalRapporterIngenBensin(false);
    }

    const ModalOpprettUnderenhetAapne = (orgnr: number, index: number) => {
        if (orgnr !== 0) {
            setnyUnderenhetId(index);
        }
        else {
            setnyUnderenhetId(9999999999999);
        }
        setModalOpprettUnderenhet(true);
    }

    const ModalOpprettUnderenhetLukk = () => {
        setModalOpprettUnderenhet(false);
    }

    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackError(false);
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1) {
            getunderenheter();
        }
        setValueTab(newValue);
    };

    const ModalEndreAktorAapne = () => {
        setModalEndreAktor(true);
    }

    const ModalEndreAktorLukk = () => {
        setModalEndreAktor(false);
    }

    const sendAktorOppdaterKontaktinfo = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        ModalEndreAktorLukk();
        var data = new FormData(event.currentTarget);
        let aktorid = "" + AktivAktorId;
        data.append('aktorid', aktorid);
        await axios.post(APIURL + "/aktor/oppdaterkontakt", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor(orgnr);
                    setSnackMelding("Kontaktopplysninger oppdatert");
                    setOpenSnack(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under oppdatering av konktaktopplysninger");
                setOpenSnackError(true);
            });
        setisLoading(false);
    }

    const sendBensinRapportering = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        data.append('aktorid', AktivUtsalgsstedId.toString());
        await axios.post(APIURL + "/aarsavgift/bensin/rapportering", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setSnackMelding("Rapportering meldt inn");
                    setOpenSnack(true);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    await getaktor(orgnr);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under innsending av rapportering");
                setOpenSnackError(true);
            });
        ModalRapporterBensinLukk();
        setisLoading(false);
    }

    const sendIngenBensinRapportering = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        data.append('aktorid', AktivUtsalgsstedId.toString());
        await axios.post(APIURL + "/aarsavgift/bensin/ingenrapportering", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then(async (response: any) => {
                if (response.status === 200) {
                    setSnackMelding("Rapportering meldt inn");
                    setOpenSnack(true);
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    await getaktor(orgnr);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under innsending av rapportering");
                setOpenSnackError(true);
            });
        ModalRapporterIngenBensinLukk();
        setisLoading(false);
    }

    const sendOpprettUnderenhet = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setisLoading(true);
        var data = new FormData(event.currentTarget);
        data.append('aktorid', AktivAktorId.toString());
        data.append('hovedorg', (orgnr ?? '').toString());
        await axios.post(APIURL + "/aarsavgift/bensin/nyttutsalgssted", data, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    getaktor(orgnr);
                    setValueTab(0);
                    setSnackMelding("Nytt utsalgssted opprettet");
                    setOpenSnack(true);
                }
                else {
                }
            })
            .catch((e: Error) => {
                setSnackMelding("Feil under innsending av nytt utsalgssted");
                setOpenSnackError(true);
            });
        ModalOpprettUnderenhetLukk();
        setisLoading(false);
    }

    function getaktor(orgnr: any) {
        axios.get(APIURL + "/aarsavgift/bensin/aktor/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setAktor(response.data);
                    setAktivAktorId(response.data[0].AktorId);
                }
                else {
                    if ((response.status === 401) || (response.status === 403)) {
                        sessionStorage.clear();
                        window.location.assign("/");
                    }
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                if ((e.response.status === 401) || (e.response.status === 403)) {
                    sessionStorage.clear();
                    window.location.assign("/");
                }
                setisLoading(false);
            });
    }

    function getunderenheter() {
        axios.get(APIURL + "/brreg/underenheter/" + orgnr, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setUnderenheter(response.data);
                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: Error) => {
                setisLoading(false);
            });
    }

    useEffect(() => {
        document.title = 'Årsavgift bensin - Justervesenet';
        getaktor(orgnr);
        setisLoading(false);
        // eslint-disable-next-line
    }, [orgnr]);

    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }}>
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <Dialog
                            fullScreen
                            open={ModalEndreAktor}
                            onClose={ModalEndreAktorLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "oppdateraktoropplysninger" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalEndreAktorLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "aktoroppdateringpersonvern" })}
                                </Alert>
                                <Box component="form" id="kontaktoppdateringform" onSubmit={sendAktorOppdaterKontaktinfo}>
                                    <Grid container>
                                        <Grid size={12} p={2}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="aktorepost"
                                                label={Text({ tid: "epost" })}
                                                name="epost"
                                                defaultValue={aktor[0]?.Epost}
                                                required
                                            />
                                        </Grid>
                                        <Grid size={12} p={2}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="aktormobil"
                                                label={Text({ tid: "mobiltelefon" })}
                                                name="mobil"
                                                defaultValue={aktor[0]?.Mobilnummer}
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="kontaktoppdateringform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "oppdater" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalEndreAktorLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullScreen
                            open={ModalOpprettUnderenhet}
                            onClose={ModalOpprettUnderenhetLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "opprettunderenhet" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalOpprettUnderenhetLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "aktoroppdateringpersonvern" })}
                                </Alert>
                                <DialogContent>
                                    <Box component="form" id="opprettunderenhetform" onSubmit={sendOpprettUnderenhet}>
                                        <Grid size={12} sx={{ mb: 1 }}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="orgnr"
                                                label={Text({ tid: "organisasjonsnummer" })}
                                                name="orgnr"
                                                defaultValue={underenheter[nyUnderenhetId]?.orgnr}
                                            />
                                        </Grid>
                                        <Grid size={12} sx={{ mb: 1 }}>
                                            <TextField
                                                margin="normal"
                                                fullWidth
                                                id="navn"
                                                label={Text({ tid: "aktornavn" })}
                                                name="navn"
                                                defaultValue={underenheter[nyUnderenhetId]?.navn}
                                                required
                                            />
                                        </Grid>
                                        <Grid container>
                                            <Grid size={6} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="besoksadresse"
                                                    label={Text({ tid: "besoksadresse" })}
                                                    name="besoksadresse"
                                                    defaultValue={underenheter[nyUnderenhetId]?.forretningsadr}
                                                    required
                                                />
                                            </Grid>
                                            <Grid size={2} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="besokspostnr"
                                                    label={Text({ tid: "postnummer" })}
                                                    name="besokspostnr"
                                                    defaultValue={underenheter[nyUnderenhetId]?.forradrpostnr}
                                                    required
                                                />
                                            </Grid>
                                            <Grid size={4} sx={{ mb: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="besokssted"
                                                    label={Text({ tid: "sted" })}
                                                    name="besokssted"
                                                    defaultValue={underenheter[nyUnderenhetId]?.forradrpoststed}
                                                    required
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid size={4} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="mobilnummer"
                                                    label={Text({ tid: "mobilnummer" })}
                                                    name="mobilnummer"
                                                />
                                            </Grid>
                                            <Grid size={4} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="epost"
                                                    label={Text({ tid: "epost" })}
                                                    name="epost"
                                                />
                                            </Grid>
                                            <Grid size={4} sx={{ mb: 1, pr: 1 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    id="telefon"
                                                    label={Text({ tid: "telefonnummer" })}
                                                    name="telefon"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="opprettunderenhetform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "opprettunderenhet" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalOpprettUnderenhetLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>


                        <Dialog
                            open={ModalRapporterBensin}
                            onClose={ModalRapporterBensinLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "oppdateraktoropplysninger" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalRapporterBensinLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "rapporterbensininfo" })}
                                </Alert>
                                <Box component="form" id="rapporterbensinform" onSubmit={sendBensinRapportering}>
                                    <Grid container>
                                        <Grid size={12} p={2}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                id="bensinomsetning"
                                                label={Text({ tid: "bensinomsetning" })}
                                                type="number"
                                                name="bensinomsetning"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="rapporterbensinform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendrapportering" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalRapporterBensinLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            fullWidth
                            maxWidth="lg"
                            open={ModalRapporterIngenBensin}
                            onClose={ModalRapporterIngenBensinLukk}
                            TransitionComponent={Transition}
                        >
                            <AppBar sx={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {Text({ tid: "ingenbensininfotittel" })}
                                    </Typography>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        onClick={ModalRapporterIngenBensinLukk}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <DialogContent>
                                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                    {Text({ tid: "ingenbensininfo" })}
                                </Alert>
                                <Box component="form" id="rapporterbensinform" onSubmit={sendIngenBensinRapportering}>
                                    <Grid container>
                                        <Grid size={12} p={2}>
                                            <TextField
                                                size="small"
                                                select
                                                fullWidth
                                                label={Text({ tid: "ingenbensinaarsak" })}
                                                name="ingenbensinaarsak"
                                                id="ingenbensinaarsak"
                                                onChange={handleSelectChangeAarsak}
                                                required
                                            >
                                                <MenuItem value="1">
                                                    {Text({ tid: "ingenbensinalternativ1" })}
                                                </MenuItem>
                                                <MenuItem value="2">
                                                    {Text({ tid: "ingenbensinalternativ2" })}
                                                </MenuItem>
                                                <MenuItem value="3">
                                                    {Text({ tid: "ingenbensinalternativ3" })}
                                                </MenuItem>
                                                <MenuItem value="4">
                                                    {Text({ tid: "ingenbensinalternativ4" })}
                                                </MenuItem>
                                                <MenuItem value="5">
                                                    {Text({ tid: "ingenbensinalternativ5" })}
                                                </MenuItem>
                                                <MenuItem value="6">
                                                    {Text({ tid: "ingenbensinalternativ6" })}
                                                </MenuItem>
                                                <MenuItem value="7">
                                                    {Text({ tid: "ingenbensinalternativ7" })}
                                                </MenuItem>
                                            </TextField>
                                            <p></p>
                                            {(bensinAarsak === '3') && (
                                                <TextField
                                                    fullWidth
                                                    type="date"
                                                    id="nedlagtdato"
                                                    label={Text({ tid: "nedlagtdato" })}
                                                    name="nedlagtdato"
                                                    required
                                                />
                                            )}
                                            {(bensinAarsak === '7') && (
                                                <div>
                                                    <TextField
                                                        fullWidth
                                                        type="date"
                                                        id="eierskiftedato"
                                                        label={Text({ tid: "eierskiftedato" })}
                                                        name="eierskiftedato"
                                                        required
                                                    />
                                                    <p></p>
                                                    <TextField
                                                        fullWidth
                                                        type="text"
                                                        id="nyttorgnr"
                                                        label={Text({ tid: "aarsavgiftnyeierorg" })}
                                                        name="nyttorgnr"
                                                    />
                                                </div>
                                            )}
                                            <p></p>
                                            <TextField
                                                multiline
                                                rows="5"
                                                fullWidth
                                                id="ingenbensinaarsakdetaljer"
                                                label={Text({ tid: "ingenbensinaarsakdetaljer" })}
                                                name="ingenbensinaarsakdetaljer"
                                                required
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                                <DialogActions>
                                    <Button
                                        size="small"
                                        type="submit"
                                        form="rapporterbensinform"
                                        variant="contained"
                                        color="success"
                                    >
                                        <SaveIcon className='jvbtnicon' />
                                        {Text({ tid: "sendrapportering" })}
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={ModalRapporterIngenBensinLukk}
                                    >
                                        <CloseIcon className='jvbtnicon' />
                                        {Text({ tid: "avbryt" })}
                                    </Button>
                                </DialogActions>
                            </DialogContent>
                        </Dialog>

                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/aarsavgift"
                            >
                                <Text tid="aarsavgift" />
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                {aktor[0]?.Navn}
                            </Typography>
                        </Breadcrumbs>
                        <Grid container spacing={2} p={2}>
                            <Grid size={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor[0]?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {orgnr}
                                        </Typography>
                                        <Grid container>
                                            <Grid size={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor[0]?.forretningsadr} {aktor[0]?.forradrpostnr} {aktor[0]?.forradrpoststed}<br />
                                                    {aktor[0]?.Nummer} {aktor[0]?.Sted}
                                                </Typography>
                                            </Grid>
                                            <Grid size={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor[0]?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid size={4}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor[0]?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" onClick={ModalEndreAktorAapne} size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid size={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box className="jvstatusboxverdi">{aktor?.[0]?.underenheter?.length || 0}</Box>
                                        </Grid>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="body1" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                <Text tid="antallutsalgssteder" />
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid size={3}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Box className="jvstatusboxverdi">{aktor?.[0]?.manglerrapportering}</Box>
                                        </Grid>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Typography variant="body1" color="text.secondary" gutterBottom className='jvtypeicon'>
                                                <Text tid="antallutmanglerrapportering" />
                                            </Typography>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid size={12}>
                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<LocalGasStationIcon color="success" />} label={"Aktive utsalgssteder (" + (aktor?.[0]?.underenheter?.length || 0) + ")"} iconPosition="start" />
                                    <Tab icon={<LocalGasStationIcon color="info" />} label={"Opprett nytt utsalgssted"} iconPosition="start" />
                                </Tabs>
                                <TabPanel value={valueTab} index={0}>
                                    {(aktor?.[0]?.underenheter?.length === 0) &&
                                        <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                            {Text({ tid: "ingenbensinutsalgssteder" })}
                                        </Alert>

                                    }
                                    {aktor[0]?.underenheter && aktor[0]?.underenheter.map(({ AktorId, Navn, Adresselinje1, Adresselinje2, Adresselinje3, Adresselinje4, Nummer, Sted, aarsrapportering, aarsrapporteringvolum, rapnedlagt }: any, index: number) => {

                                        var cardcolor = "cardtaksameter";
                                        if (localStorage.getItem("jvtheme") === "dark") {
                                            cardcolor = "cardtaksameterdark";
                                        }
                                        if (aarsrapportering === 1) {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardtaksameter";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardtaksameter";
                                            }
                                        }
                                        else {
                                            if (localStorage.getItem("jvtheme") === "dark") {
                                                cardcolor = "cardstansevedtakdark";
                                            }
                                            if (localStorage.getItem("jvtheme") === "light") {
                                                cardcolor = "cardstansevedtak";
                                            }
                                        }

                                        return <span key={AktorId}>
                                            <Card className={cardcolor} sx={{ mb: 2 }}>
                                                <Grid size={12}>
                                                </Grid>
                                                <CardContent>
                                                    <Grid container>
                                                        <Grid size={12}>
                                                            <Typography variant="body1" color="text.secondary" className='jvtexttitlexl' sx={{ mb: 2 }}>
                                                                <BadgeIcon className='jvtexticontitlexl' /> <b>{Navn}</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid size={12}>
                                                            <PlaceIcon className='jvtexticontitlexl' /> {Adresselinje1 || "(mangler adresse)"} {Adresselinje2} {Adresselinje3} {Adresselinje4}, {Nummer} {Sted}
                                                            <Typography variant="body1" color="text.secondary" sx={{ mt: 2, mb: 2 }}>
                                                                <b>
                                                                    {((aarsrapportering === 1) && (rapnedlagt === 0)) &&
                                                                        <div>
                                                                            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                                                                                {Text({ tid: "aarsavgiftrapokvolum" })} <b>{aarsrapporteringvolum}</b>
                                                                            </Typography>
                                                                            <Button size="small" variant="contained" color="warning" sx={{ mr: 1 }} onClick={e => ModalRapporterBensinAapne(AktorId)}><EditIcon className='jvbtnicon' /> {Text({ tid: "endrerapportering" })}</Button>
                                                                            <Button size="small" variant="contained" color="error" sx={{ mr: 1 }} onClick={e => ModalRapporterIngenBensinAapne(AktorId)}><CloseIcon className='jvbtnicon' /> {Text({ tid: "rapporteringenbensin" })}</Button>
                                                                        </div>
                                                                    }
                                                                    {((aarsrapportering === 0) && (rapnedlagt === 0)) &&
                                                                        <div>
                                                                            <Alert severity="error" sx={{ mb: 2 }}>
                                                                                {Text({ tid: "aarsavgiftrapmangler" })}
                                                                            </Alert>
                                                                            <Button size="small" variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalRapporterBensinAapne(AktorId)}><Send className='jvbtnicon' /> {Text({ tid: "nyrapportering" })}</Button>
                                                                            <Button size="small" variant="contained" color="error" sx={{ mr: 1 }} onClick={e => ModalRapporterIngenBensinAapne(AktorId)}><CloseIcon className='jvbtnicon' /> {Text({ tid: "rapporteringenbensin" })}</Button>
                                                                        </div>
                                                                    }
                                                                    {(rapnedlagt === 1) &&
                                                                        <div>
                                                                            <Alert severity="warning" sx={{ mb: 2 }}>
                                                                                {Text({ tid: "aarsavgiftrapbehandling" })}
                                                                            </Alert>
                                                                            <Button size="small" variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalRapporterBensinAapne(AktorId)}><Send className='jvbtnicon' /> {Text({ tid: "nyrapportering" })}</Button>
                                                                            <Button size="small" variant="contained" color="error" sx={{ mr: 1 }} onClick={e => ModalRapporterIngenBensinAapne(AktorId)}><CloseIcon className='jvbtnicon' /> {Text({ tid: "rapporteringenbensin" })}</Button>
                                                                        </div>
                                                                    }
                                                                </b>
                                                            </Typography>

                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </span>
                                    })
                                    }
                                </TabPanel>
                                <TabPanel value={valueTab} index={1}>
                                    <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                                        {Text({ tid: "nyttbensinutsalgsstedinfo" })}
                                    </Alert>

                                    <List>
                                        {underenheter?.map(({ orgnr, navn, forretningsadr, forradrpostnr, forradrpoststed, metricaktiv, metriceksisterer }: any, index: number) => {
                                            if (metricaktiv === 0) {
                                                return <ListItem
                                                    secondaryAction={
                                                        <IconButton edge="end" aria-label="delete">
                                                            <AddCircleIcon onClick={e => ModalOpprettUnderenhetAapne(orgnr, index)} />
                                                        </IconButton>
                                                    }
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <PlaceIcon />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={orgnr + " - " + navn}
                                                        secondary={forretningsadr + ", " + forradrpostnr + " " + forradrpoststed}
                                                    />
                                                </ListItem>
                                            }
                                            else return "";
                                        })
                                        }
                                    </List>
                                    <IconButton edge="end" aria-label="delete">
                                        <Button size="small" variant="contained" color="primary" sx={{ mr: 1 }} onClick={e => ModalOpprettUnderenhetAapne(0, 0)}><Send className='jvbtnicon' /> {Text({ tid: "nyunderenhetutenorgnr" })}</Button>
                                    </IconButton>
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main >
    )
}
export default AarsavgiftBensin;